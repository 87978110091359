import React from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import ImageHeader from '../../components/ImageHeader'
import IndexFilter from '../../components/IndexFilter'
import AdComponent from "../../components/AdComponent"
import Layout from "../../components/Layout"
import Seo from '../../components/Seo'
import {useStaticQuery, graphql} from 'gatsby'
import '../../styles/index.scss'

const AuthorsPage = () => {
    const data = useStaticQuery(graphql`
        query AuthorQuery {
            allWpMultiauthor(sort: {order: ASC, fields: authorFields___alphabetizedName}) {
                nodes {
                    name
                    id
                    slug
                    count
                    authorFields {
                        alphabetizedName
                    }
                }
            }
            wp {
                resourcesGeneralSettings {
                    resourceLibraryHeader {
                        headerImage {
                            sourceUrl
                        }
                    }
                }
            }
            allWpAd(
                filter: {adPositions: {nodes: {elemMatch: {slug: {in: ["above-index-list"]}}}}}
            ) {
                nodes {
                    id
                    title
                    adPositions {
                        nodes {
                            slug
                        }
                    }
                    adFields {
                        altText
                        endDate
                        externalLink
                        startDate
                    }
                    featuredImage {
                        node {
                            mediaItemUrl
                        }
                    }
                }
            }
        }
    `)

    //get all topics with a parent of null and map them
    //while mapping parent topics, find and map all children topics with matching parent dbid
    return (
        <Layout>
            <Seo
                title="Authors"
                description="Authors Index"
            />
            <ImageHeader title="Author Index"
                         image={data.wp.resourcesGeneralSettings.resourceLibraryHeader.headerImage.sourceUrl}/>
            <Breadcrumbs title="Authors" isResource={true} />
            <IndexFilter taxonomy="topics" />
            <AdComponent ads={data.allWpAd.nodes} position="above-index-list" />
            <section className="index container">
                <div>
                    <ul className="index__group__children">
                        {data.allWpMultiauthor.nodes.filter((term) => term.count > 0).map((term, index) =>
                            <li key={index} className="index__group__children__child">
                                <a href={`/resources/authors/${term.slug}`}>
                                    <div>
                                        {term.name}
                                    </div>
                                    <span>
                                            {`${term.count.toString()} resources`}
                                        </span>
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
            <Breadcrumbs title="Authors" isResource={true} />
        </Layout>
    )
}

export default AuthorsPage
